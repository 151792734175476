import apiAxios from '../../services/apiAxios';
import { getAccesos } from '../../helpers/user_auth';
import helpers from '../../mixins/helpers';

const state = {
  userId: 0,
  username: '',
  avatar: '',
  nombre: '',
  tokenString: '',
  userRolId: 0,

  nav: [],
  accesos: [],

  // clienteId: '',
  // cliente: '',
  
  passwordVencido: false
};

const mutations = {
  'LOGIN'(state, params) {
    state.userId = params.user.id;
    state.username = params.user.username;
    state.apellido = params.user.apellido;
    state.nombre = params.user.nombre;
    state.avatar = params.user.avatar;
    state.userRolId = params.user.usuarioRolId;
    
    state.tokenString = params.tokenString;

    state.accesos = getAccesos(state.userRolId);

    getMenu(state);
  },
  'LOGOUT'(state) {
    state.userId = 0;
    state.username = '';
    state.apellido = '';
    state.nombre = '';
    state.avatar = '';
    state.userRolId = 0;

    state.tokenString = '';

    state.accesos = [];
    state.nav = [];
  },
  'PERFIL'(state, params) {
    state.apellido = params.apellido;
    state.nombre = params.nombre;
    state.avatar = params.avatar;
  },
};

const actions = {
  login: ( { commit  }, params) => {
    return new Promise((resolve, reject) => {
      commit('LOGOUT');

      apiAxios.post('auth/login', params) 
        .then(res => {
          commit('LOGIN', res.data);

          const now = new Date();
          const expirationDate = new Date(now.getTime() + 28800000);
          localStorage.setItem('username', res.data.user.username);
          localStorage.setItem('token', res.data.tokenString);
          localStorage.setItem('expirationDate', expirationDate);

          resolve();
        })
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  tryLogin ( { commit } ) {
    return new Promise((resolve, reject) => {
      /* Recuperamos los datos del localStorage */  
      var username = localStorage.getItem('username');
      var tokenString = localStorage.getItem('token');
      var expirationDate = new Date(localStorage.getItem('expirationDate', expirationDate));
      var currentDate = new Date();

      /* Validamos datos del usuario y la fecha de expiración */
      if (username && tokenString
          && expirationDate.getTime() > currentDate.getTime()) {
            
        var credenciales = {
          username: username,
          tokenString: tokenString
        }
        apiAxios.post('auth/auto', credenciales) 
          .then(res => {
            commit('LOGIN', res.data);

            const now = new Date();
            const expirationDate = new Date(now.getTime() + 28800000);
            localStorage.setItem('username', res.data.user.username);
            localStorage.setItem('token', res.data.tokenString);
            localStorage.setItem('expirationDate', expirationDate);

            resolve();
          })
          .catch(error => reject(helpers.methods.getErrorMessage(error)));
        } else {
          reject();
        }
    })
  },
  logout: ({commit}) => {
      commit('LOGOUT');

      localStorage.removeItem('username');
      localStorage.removeItem('token');
      localStorage.removeItem('expirationDate');
  },
  forgotPassword ( {}, params ) {
    return new Promise((resolve, reject) => { 
      apiAxios.post('auth/forgot', params)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  register ( {}, params ) {
    return new Promise((resolve, reject) => { 
      apiAxios.post('auth/register', params)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  resetPassword ( {}, params ) {
    return new Promise((resolve, reject) => { 
      apiAxios.post('auth/reset-password', params)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
};

function getMenu(state) {
  state.nav = [];

  state.nav.push({
      name: 'Inicio',
      url: '/dashboard',
      icon: 'icon-home',
      badge: {
        variant: 'primary',
        text: ''
      }
    });

    
    /* Programas */
    if (tieneAccesoRuta(state, 'programas')) {
      addNavItem(null, 'Programas', 'programas', 'icon-list');
    }

    /* Formularios */
    if (tieneAccesoRuta(state, 'formularios')) {
      addNavItem(null, 'Formularios', 'formularios', 'icon-list');
    }

    /* Solicitudes */
    if (tieneAccesoRuta(state, 'solicitudes')) {
      addNavItem(null, 'Solicitudes', 'solicitudes', 'icon-list');
    }

    /* Seguridad */
    if (tieneAccesoRuta(state, 'tablas')) {
      let parentItem = addNavItem(null, 'Tablas', 'tablas', 'icon-list');

      addNavItemValidated(parentItem, 'Departamentos', 'tablas/departamentos', 'icon-list');
      addNavItemValidated(parentItem, 'Procesos', 'tablas/procesos', 'icon-list');
      addNavItemValidated(parentItem, 'Estados de Procesos', 'tablas/procesos-estados', 'icon-list');
      addNavItemValidated(parentItem, 'Estados de Solicitudes', 'tablas/solicitudes-estados', 'icon-list');
      addNavItemValidated(parentItem, 'Listas', 'tablas/formularios-listas', 'icon-list');
    }

    /* Seguridad */
    if (tieneAccesoRuta(state, 'seguridad')) {
      let parentItem = addNavItem(null, 'Seguridad', 'seguridad', 'icon-lock');

      addNavItemValidated(parentItem, 'Usuarios', 'seguridad/usuarios', 'icon-people');
    }
}

function addNavItem(parent, navItem, navUrl, navIcon) {
  let item = {
    name: navItem,
    url: '/' + navUrl.trim(),
    icon: navIcon
  }

  let addItem = true;
  if (parent) {
    if (!parent.children) {
      parent.children = [];  
    } 
    parent.children.push(item);
    addItem = false;
  }
  if (addItem) {
    state.nav.push(item);
  }

  return item;
}
function addNavItemValidated(parent, navItem, navUrl, navIcon) {
  let item;

  if (tieneAccesoRuta(state, navUrl)) {
    item = {
      name: navItem,
      url: '/' + navUrl.trim(),
      icon: navIcon
    }

    let addItem = true;
    if (parent) {
      if (!parent.children) {
        parent.children = [];  
      } 
      parent.children.push(item);
      addItem = false;
    }
    if (addItem) {
      state.nav.push(item);
    }
  }
  return item;
}

function tieneAccesoRuta(state, ruta) {
  if (state.userRolId == 1 || state.userRolId == 2) {
    return true;
  } 

  let accesoHabilitado = state.accesos.find((acceso) => {
    return acceso.route.trim() == ruta 
      || '/' + acceso.route.trim() == ruta
      || '/' + acceso.route.trim() + '/' == ruta;
  });

  if (accesoHabilitado) { 
    return true;
  }

  if (state.userId == 1) {
    console.log(ruta);
  }

  return false;
}

const getters = {
  loggedIn(state) {
    if (state.username) {
      if (state.username != '') {
        var expirationDate = new Date(localStorage.getItem('expirationDate', expirationDate));
        var currentDate = new Date();

        /* Validamos datos del usuario y la fecha de expiración */
        if (expirationDate.getTime() > currentDate.getTime()) {
          return true;
        }
      }
    }
    return false;
  },
  userId (state) {
    return state.userId;
  },
  username (state) {
    return state.username;
  },
  apellido (state) {
    return state.apellido;
  },
  nombre (state) {
    return state.nombre;
  },
  avatar (state) {
    return state.avatar;
  },
  tokenString (state) {
    return state.tokenString;
  },
  passwordVencido(state) {
    return (/true/i).test(state.passwordVencido);
  },
  nav(state) {
    return state.nav;
  },
  tieneAcceso: (state) => (route) => {
    if (state.userRolId == 1 || state.userRolId == 2) {
      return true;
    } 

    let routePath = route.path;

    let lastMatched;
    let lastMatchedPath;
    if (route.matched) {
      if (route.matched.length != 0) {
        lastMatched = route.matched.slice(-1);
        if (lastMatched) {
          if (lastMatched.length != 0) {
            lastMatchedPath = lastMatched[0].path;
          }
        }
      }
    }

    if (routePath != '/dashboard' && routePath != '/') {
      if (state.accesos.find(obj => '/' + obj.route.trim() == lastMatchedPath
          || '/' + obj.route.trim() + '/' == lastMatchedPath)) {
        return true;
      }
    } else {
      return true;
    }

    if(state.userId == 1) {
      console.log(routePath);
    }

    return false;
  },
  tieneAccesoByModulo: (state) => (modulo) => {
    if (state.userRolId == 1 || state.userRolId == 2) {
      return true;
    } 

    if(state.accesos.find((acceso) => { return acceso.modulo.trim() == modulo })) {
      return true;
    };
 
    if (state.userId == 1) {
      console.log(modulo);
    }
  
    return false;
  },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}