import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Inicio = () => import('@/views/pages/Home')
const Login = () => import('@/views/pages/Login')
// const Register = () => import('@/views/pages/Register')
const ResetPassword = () => import('@/views/pages/Reset')
const ForgotPassword = () => import('@/views/pages/Forgot')
const Register = () => import('@/views/pages/Reset')

const Departamentos = () => import('@/views/departamentos/List')
const DepartamentoAdd = () => import('@/views/departamentos/ABM')
const DepartamentoEdit = () => import('@/views/departamentos/ABM')
const DepartamentoDatos = () => import('@/views/departamentos/Data')
const Solicitudes = () => import('@/views/solicitudes/List')
const SolicitudAdd = () => import('@/views/solicitudes/ABM')
const SolicitudEdit = () => import('@/views/solicitudes/ABM')
const SolicitudDatos = () => import('@/views/solicitudes/Data')
const SolicitudesEstados = () => import('@/views/solicitudesEstados/List')
const SolicitudEstadoAdd = () => import('@/views/solicitudesEstados/ABM')
const SolicitudEstadoEdit = () => import('@/views/solicitudesEstados/ABM')
const SolicitudEstadoDatos = () => import('@/views/solicitudesEstados/Data')
const Formularios = () => import('@/views/formularios/List')
const FormularioAdd = () => import('@/views/formularios/ABM')
const FormularioEdit = () => import('@/views/formularios/ABM')
const FormularioDatos = () => import('@/views/formularios/Data')
const FormulariosListas = () => import('@/views/formulariosListas/List')
const FormularioListaAdd = () => import('@/views/formulariosListas/ABM')
const FormularioListaEdit = () => import('@/views/formulariosListas/ABM')
const FormularioListaDatos = () => import('@/views/formulariosListas/Data')
const Procesos = () => import('@/views/procesos/List')
const ProcesoAdd = () => import('@/views/procesos/ABM')
const ProcesoEdit = () => import('@/views/procesos/ABM')
const ProcesoDatos = () => import('@/views/procesos/Data')
const ProcesosEstados = () => import('@/views/procesosEstados/List')
const ProcesoEstadoAdd = () => import('@/views/procesosEstados/ABM')
const ProcesoEstadoEdit = () => import('@/views/procesosEstados/ABM')
const ProcesoEstadoDatos = () => import('@/views/procesosEstados/Data')
const Programas = () => import('@/views/programas/List')
const ProgramaAdd = () => import('@/views/programas/ABM')
const ProgramaEdit = () => import('@/views/programas/ABM')
const ProgramaDatos = () => import('@/views/programas/Data')

const Dashboard = () => import('@/views/Dashboard')
const Usuarios = () => import('@/views/usuarios/List')
const UsuarioEdit = () => import('@/views/usuarios/ABM')
const UsuarioDatos = () => import('@/views/usuarios/Data')
const Perfil = () => import('@/views/usuarios/Perfil')
const Pass = () => import('@/views/usuarios/Pass')

Vue.use(Router)

function requireAuth (to, from, next) {
  let loggedIn = (/true/i).test(store.getters['user/loggedIn']);

  if (loggedIn) {
    if ( !store.getters['user/tieneAcceso'](to) ) {
      /* Acceso denegado */
      next ('/');
    } else {
      /* Acceso habilitado */
      next ();
    }
  } else {
    store.dispatch('user/tryLogin')
      .then(() => {
        loggedIn = (/true/i).test(store.getters['user/loggedIn']);

        if (loggedIn) {
          if ( !store.getters['user/tieneAcceso'](to) ) {
            /* Acceso denegado */
            next ('/');
          } else {
            /* Acceso habilitado */
            next ();
          }
        } else {
          next ('/login')
        }
      })
      .catch(() => next ('/login'));
  }
}

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      meta: { label: 'Inicio'},
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          // meta: { label: 'Inicio'},
          component: Dashboard,
          beforeEnter: requireAuth,
        },

        /* Programas */
        {
          path: 'programas',
          meta: { label: 'Programas'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Programas',
              meta: { label: 'Listado de Programas'},
              component: Programas,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Programa'},
              name: 'ProgramaAdd',
              component: ProgramaAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Programa'},
              name: 'ProgramaEdit',
              component: ProgramaEdit,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Informacion del Programa'},
              name: 'ProgramaDatos',
              component: ProgramaDatos,
              beforeEnter: requireAuth,
            },
          ]
        },

        /* Formularios */
        {
          path: 'formularios',
          meta: { label: 'Formularios'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Formularios',
              meta: { label: 'Listado de Formularios'},
              component: Formularios,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Formulario'},
              name: 'FormularioAdd',
              component: FormularioAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Formulario'},
              name: 'FormularioEdit',
              component: FormularioEdit,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Informacion del Formulario'},
              name: 'FormularioDatos',
              component: FormularioDatos,
              beforeEnter: requireAuth,
            },
          ]
        },

        /* Solicitudes */
        {
          path: 'solicitudes',
          meta: { label: 'Solicitudes'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Solicitudes',
              meta: { label: 'Listado de Solicitudes'},
              component: Solicitudes,
              beforeEnter: requireAuth,
            },
            {
              path: 'add/:formularioId',
              meta: { label: 'Información de la Solicitud'},
              name: 'SolicitudAdd',
              component: SolicitudAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información de la Solicitud'},
              name: 'SolicitudEdit',
              component: SolicitudEdit,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Información de la Solicitud'},
              name: 'SolicitudDatos',
              component: SolicitudDatos,
              beforeEnter: requireAuth,
            },
          ]
        },

        /* Tablas */
        {
          path: 'tablas',
          meta: { label: 'Tablas'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: 'departamentos',
              meta: { label: 'Departamentos'},
              component: {
                render (c) { return c('router-view') }
              },
              beforeEnter: requireAuth,
              children: [
                {
                  path: '',
                  meta: { label: 'Listado de Departamentos'},
                  name: 'Departamentos',
                  component: Departamentos,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Información del Departamento'},
                  name: 'DepartamentoAdd',
                  component: DepartamentoAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Información del Departamento'},
                  name: 'DepartamentoEdit',
                  component: DepartamentoEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Información del Departamento'},
                  name: 'DepartamentoDatos',
                  component: DepartamentoDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
            {
              path: 'procesos',
              meta: { label: 'Procesos'},
              component: {
                render (c) { return c('router-view') }
              },
              beforeEnter: requireAuth,
              children: [
                {
                  path: '',
                  meta: { label: 'Listado de Procesos'},
                  name: 'Procesos',
                  component: Procesos,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Información del Proceso'},
                  name: 'ProcesoAdd',
                  component: ProcesoAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Información del Proceso'},
                  name: 'ProcesoEdit',
                  component: ProcesoEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Información del Proceso'},
                  name: 'ProcesoDatos',
                  component: ProcesoDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
            {
              path: 'procesos-estados',
              meta: { label: 'Estados de Procesos'},
              component: {
                render (c) { return c('router-view') }
              },
              beforeEnter: requireAuth,
              children: [
                {
                  path: '',
                  meta: { label: 'Listado de Estados de Procesos'},
                  name: 'ProcesosEstados',
                  component: ProcesosEstados,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Información del Estado'},
                  name: 'ProcesoEstadoAdd',
                  component: ProcesoEstadoAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Información del Estado'},
                  name: 'ProcesoEstadoEdit',
                  component: ProcesoEstadoEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Información del Estado'},
                  name: 'ProcesoEstadoDatos',
                  component: ProcesoEstadoDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
            {
              path: 'solicitudes-estados',
              meta: { label: 'Estados de Solicitudes'},
              component: {
                render (c) { return c('router-view') }
              },
              beforeEnter: requireAuth,
              children: [
                {
                  path: '',
                  meta: { label: 'Listado de Estados'},
                  name: 'SolicitudesEstados',
                  component: SolicitudesEstados,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Información del Estado'},
                  name: 'SolicitudEstadoAdd',
                  component: SolicitudEstadoAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Información del Estado'},
                  name: 'SolicitudEstadoEdit',
                  component: SolicitudEstadoEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Información del Estado'},
                  name: 'SolicitudEstadoDatos',
                  component: SolicitudEstadoDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
            {
              path: 'formularios-listas',
              meta: { label: 'Listas de Formularios'},
              component: {
                render (c) { return c('router-view') }
              },
              beforeEnter: requireAuth,
              children: [
                {
                  path: '',
                  meta: { label: 'Listado de Listas'},
                  name: 'FormulariosListas',
                  component: FormulariosListas,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'add',
                  meta: { label: 'Información de la Lista'},
                  name: 'FormularioListaAdd',
                  component: FormularioListaAdd,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id/edit',
                  meta: { label: 'Información de la Lista'},
                  name: 'FormularioListaEdit',
                  component: FormularioListaEdit,
                  beforeEnter: requireAuth,
                },
                {
                  path: ':id',
                  meta: { label: 'Información de la Lista'},
                  name: 'FormularioListaDatos',
                  component: FormularioListaDatos,
                  beforeEnter: requireAuth,
                },
              ]
            },
          ]
        },

        /* Seguridad */
        {
            path: 'seguridad',
            meta: { label: 'Seguridad'},
            component: {
              render (c) { return c('router-view') }
            },
            beforeEnter: requireAuth,
            children: [
              /* Usuarios */
              {
                path: 'usuarios',
                meta: { label: 'Usuarios'},
                component: {
                  render (c) { return c('router-view') }
                },
                beforeEnter: requireAuth,
                children: [
                  {
                    path: '',
                    name: 'Usuarios',
                    meta: { label: 'Listado de Usuarios'},
                    component: Usuarios,
                    beforeEnter: requireAuth,
                  },
                  {
                    path: ':id',
                    meta: { label: 'Informacion del Usuario'},
                    name: 'UsuarioDatos',
                    component: UsuarioDatos,
                    beforeEnter: requireAuth,
                  },
                  {
                    path: ':id/edit',
                    meta: { label: 'Información del Usuario'},
                    name: 'UsuarioEdit',
                    component: UsuarioEdit,
                    beforeEnter: requireAuth,
                  }
                ]
              }
            ]
        },
        {
          path: '/perfil',
          name: 'Perfil',
          component: Perfil,
          beforeEnter: requireAuth,
        },    
        {
          path: '/pwd',
          name: 'Pass',
          meta: { label: 'Actualización de la Contraseña'},
          component: Pass,
          beforeEnter: requireAuth,
        },    
   
      ]
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/reset-pwd',
      name: 'ResetPassword',
      component: ResetPassword,
      props: { register: false }
    },
    {
      path: '/registro',
      name: 'Register',
      component: Register,
      props: { register: true }
    },
    {
      path: '/forgot',
      name: 'ForgotPassword',
      component: ForgotPassword
    },    
  ]
})

export default router;