import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: '',
    formularioId: '',
    programa: null,
    formulario: null,
    personaFisica: false,
    personaJuridica: false,
    nombre: '',
    tipoDocumentoId: 0,
    tipoDocumento: null,
    tipoDocumentoNombre: null,
    numeroDocumento: '',
    email: '',
    solicitudEstado: '',
    departamento: '',
    proceso: '',
    procesoEstado: '',
    numeroLegajo: '',
    numeroActa: '',
    importeAprobado: 0,
    rowVersion: null,

    campos: [],
    uploadingFiles: false,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, solicitudId) {
        state.id = solicitudId || '';
        state.formularioId = '';
        state.programa = null;
        state.formulario = null;
        state.personaFisica = false;
        state.personaJuridica = false;

        state.nombre = '';
        state.tipoDocumentoId = 0;
        state.tipoDocumento = null;
        state.tipoDocumentoNombre = null;
        state.numeroDocumento = '';
        state.email = '';
        state.solicitudEstado = '';
        state.departamento = '';
        state.proceso = '';
        state.procesoEstado = '';
        state.numeroLegajo = '';
        state.numeroActa = '';
        state.importeAprobado = 0;
        state.rowVersion = null;

        state.campos = [];
    },
    'SET_SOLICITUD'(state, solicitud) {
        state.formularioId = solicitud.formularioId;
        state.programa = solicitud.programa;
        state.formulario = solicitud.formulario;
        state.personaFisica = (/true/i).test(solicitud.personaFisica);
        state.personaJuridica = (/true/i).test(solicitud.personaJuridica);

        state.nombre = solicitud.nombre.trim();
        state.tipoDocumentoId = solicitud.tipoDocumento? solicitud.tipoDocumento.id : 0;
        state.tipoDocumento = solicitud.tipoDocumento;
        state.tipoDocumentoNombre = solicitud.tipoDocumento? solicitud.tipoDocumento.nombre.trim() : null;
        state.numeroDocumento = solicitud.numeroDocumento.trim();
        state.email = solicitud.email.trim();
        state.solicitudEstado = solicitud.solicitudEstado.trim();
        state.departamento = solicitud.departamento.trim();
        state.proceso = solicitud.proceso.trim();
        state.procesoEstado = solicitud.procesoEstado.trim();
        state.numeroLegajo = solicitud.numeroLegajo.trim();
        state.numeroActa = solicitud.numeroActa.trim();
        state.importeAprobado = parseFloat(solicitud.importeAprobado);
        state.rowVersion = solicitud.rowVersion;
        
        state.campos = solicitud.campos.map(campo => ({
            id: campo.id,
            formularioCampoId: campo.formularioCampoId,
            nombre: campo.nombre.trim(),
            descripcion: campo.descripcion.trim(),
            formularioCampoTipo: campo.formularioCampoTipo,
            formularioListaId: campo.formularioListaId,
            longitudMaxima: campo.longitudMaxima,
            orden: campo.orden,
            requerido: campo.requerido,
            valor: campo.valor,
            lista: campo.lista,
            selectList: getSelectList(state, campo), // campo.lista.map(item => item.nombre.trim()),
            file: null,
            url: campo.url,
            publicId: campo.publicId,
            inputId: `c-${campo.formularioCampoId.toString()}`,
            valido: null,
            invalidoMsj: '',

            camposGrupo: campo.camposGrupo.map(campoGrupo => ({
                id: campoGrupo.id,
                formularioCampoId: campo.formularioCampoId,
                formularioCampoGrupoId: campoGrupo.formularioCampoGrupoId,
                seleccionado: (/true/i).test(campoGrupo.seleccionado),
                nombre: campoGrupo.nombre.trim(),
                formularioCampoTipo: campoGrupo.formularioCampoTipo,
                longitudMaxima: campoGrupo.longitudMaxima,
                orden: campoGrupo.orden,
                valor: campoGrupo.valor,
                file: null,
                url: campoGrupo.url,
                publicId: campoGrupo.publicId,
                inputId: `cg-${campoGrupo.formularioCampoGrupoId.toString()}`,
                valido: null,
                invalidoMsj: '',
            }))
        }));
    },
    'SET_TIPO_DOCUMENTO'(state, tipoDocumento) {
        state.tipoDocumento = tipoDocumento;
        if (tipoDocumento) {
            state.tipoDocumentoId = tipoDocumento.id? tipoDocumento.id : 0;
            state.tipoDocumentoNombre = tipoDocumento.nombre? tipoDocumento.nombre.trim() : null;
        } else {
            state.tipoDocumentoId = 0;
            state.tipoDocumentoNombre = null;
        }
    },
    'SET_UPLOADING'(state) {
        state.uploadingFiles = true;
    },
    'SET_VALOR_FILE'(state, params) {
        let campo = state.campos.find(t => t.formularioCampoId == params.formularioCampoId);
        if (campo) {
            if (params.formularioCampoGrupoId == 0) {
                campo.valor = params.valor;
                campo.url = params.url;
                campo.publicId = params.publicId;
            } else {
                if (campo.camposGrupo) {
                    let campoGrupo = campo.camposGrupo.find(t => t.formularioCampoGrupoId == params.formularioCampoGrupoId);
                    if (campoGrupo) {
                        campoGrupo.valor = params.valor;
                        campoGrupo.url = params.url;
                        campoGrupo.publicId = params.publicId;
                    }
                }
            }
        }
        state.uploadingFiles = false;
    },
    'DELETE_FILE'(state, params) {
        let campo = state.campos.find(t => t.formularioCampoId == params.formularioCampoId);
        if (campo) {
            if (params.formularioCampoGrupoId == 0) {
                campo.file = null;
                campo.valor = '';
                campo.url = '';
                campo.publicId = '';
            } else {
                if (campo.camposGrupo) {
                    let campoGrupo = campo.camposGrupo.find(t => t.formularioCampoGrupoId == params.formularioCampoGrupoId);
                    if (campoGrupo) {
                        campoGrupo.file = null;
                        campoGrupo.valor = '';
                        campoGrupo.url = '';
                        campoGrupo.publicId = '';
                    }
                }
            }
        }
    },
    'SET_VALOR_LISTA'(state, params) {
        if (params.item) {
            let campo = state.campos.find(t => t.formularioCampoId == params.campo.formularioCampoId);
            if (campo) {
                campo.valor = params.item.nombre;
            }
        }
    },
};

function getSelectList(state, campo) {
    let selectList = campo.lista.map(item => item.nombre.trim());

    /* En caso de altas de solicitudes agregamos el campo vacío */
    if (state.solicitudId == '') {
        selectList.push(null);
    }
    
    return selectList;
}

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, solicitudId ) {
        return new Promise((resolve, reject) => {
            solicitudId = solicitudId || '';

            commit('INICIALIZAR', solicitudId);
            
            if (solicitudId != '') {
                apiAxios.get(`solicitudes/${solicitudId}/abm`) 
                    .then(res => {
                        commit('SET_SOLICITUD', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
    inicializarFormulario( { commit }, formularioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            apiAxios.get(`solicitudes/formulario/${formularioId}`) 
                .then(res => {
                    commit('SET_SOLICITUD', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    setTipoDocumento ( { commit }, tipoDocumento ) {
        return new Promise((resolve, reject) => {
            commit('SET_TIPO_DOCUMENTO', tipoDocumento);
            resolve();
        })
    },
    setValorLista ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('SET_VALOR_LISTA', params);
            resolve();
        })
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = true;
            let errorMessage = '';
            
            var filePendiente = state.campos
                .find(t => t.formularioCampoTipo == 'File' && t.valor == '' && t.file);
            if (filePendiente) {
                save = false;
                errorMessage = 'Quedan documentos pendientes de procesar. Espere a que terminen de procesarse para registrar la solicitud';
            }

            if (save) {
                let solicitud= {
                    formularioId: state.formularioId,
                    nombre: state.nombre.trim(),
                    tipoDocumentoId: state.tipoDocumentoId,
                    numeroDocumento: state.numeroDocumento.trim(),
                    email: state.email.trim(),
                    numeroLegajo: state.numeroLegajo.trim(),
                    numeroActa: state.numeroActa.trim(),
                    importeAprobado: parseFloat(state.importeAprobado),
                    campos: state.campos.map(campo => ({
                        formularioCampoId: campo.formularioCampoId,
                        valor: campo.valor,
                        publicId: campo.publicId,
                        camposGrupo: campo.camposGrupo.map(campoGrupo => ({
                            formularioCampoGrupoId: campoGrupo.formularioCampoGrupoId,
                            seleccionado: campo.valor.trim() == campoGrupo.nombre.trim()? true : false,
                            valor: campoGrupo.valor,
                            publicId: campoGrupo.publicId,
                        }))
                    })),
                };

                let url = 'solicitudes';
                if (state.id != '') {
                    solicitud.rowVersion = state.rowVersion;
                    url = `solicitudes/${state.id}`;
                }

                apiAxios.post(url, solicitud)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
    cancel ( { state } ) {
        return new Promise((resolve, reject) => {
            if (state.id == '') {
                let promises = [];

                /* Verifico si hay imagenes pendientes y las cancelo */
                state.campos
                    .filter(t => t.formularioCampoTipo == 'File' && t.valor != '' && t.file)
                    .forEach(campo => {
                        promises.push(apiAxios.post(`solicitudes/files/${campo.publicId}/delete`));
                });

                state.campos
                    .forEach(campo => {
                        if (campo.camposGrupo) {
                            campo.camposGrupo
                                .filter(t => t.formularioCampoTipo == 'File' && t.valor != '' && t.file)
                                .forEach(campoGrupo => {
                                    promises.push(apiAxios.post(`public/solicitud/files/${campoGrupo.publicId}/delete`));
                                })
                        }
                });

                if (promises.length != 0) {
                    Promise.all(promises)
                        .then(() => resolve())
                        .catch(error => reject(helpers.methods.getErrorMessage(error)));
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        })
    },

    uploadFile ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            /* Validamos los archivos a subir */
            let fileName = campo.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(campo.file.size / 1048576);

            if (fileExtension != 'jpg' 
                & fileExtension != 'png'
                & fileExtension != 'jpeg'
                & fileExtension != 'pdf') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten imágenes de tipo jpg, png o jpeg.';
            }

            if (fileSize >= 3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten imágenes de hasta 3MB.';
            }

            let formularioCampoGrupoId = 0;
            if ('formularioCampoGrupoId' in campo) {
                formularioCampoGrupoId = campo.formularioCampoGrupoId;
            }

            commit('SET_UPLOADING');

            let formData = new FormData();
            formData.append('file', campo.file);

            let options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            }
            apiAxios.post('solicitudes/files', formData, options)
                .then(res => {
                    commit('SET_VALOR_FILE', {
                        formularioCampoId: campo.formularioCampoId,
                        formularioCampoGrupoId,
                        valor: res.data.fileName,
                        url: res.data.url,
                        publicId: res.data.publicId
                    });
                    resolve();
                })
                .catch(error => {
                    let params = {
                        formularioCampoId: campo.formularioCampoId,
                        formularioCampoGrupoId,
                    }
                    commit('DELETE_FILE', params);
                    
                    reject(helpers.methods.getErrorMessage(error));
                });
        })
    },
    deleteFile ( { commit }, campo ) {
        return new Promise((resolve, reject) => {
            let formularioCampoGrupoId = 0;
            if ('formularioCampoGrupoId' in campo) {
                formularioCampoGrupoId = campo.formularioCampoGrupoId;
            }

            let params = {
                formularioCampoId: campo.formularioCampoId,
                formularioCampoGrupoId,
            }

            commit('DELETE_FILE', params);
            resolve();
        })
    },
    replaceFile ( {}, params ) {
        return new Promise((resolve, reject) => {
            /* Validamos los archivos a subir */
            let fileName = params.file.name;
            let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            let fileSize = parseFloat(params.file.size / 1048576);

            if (fileExtension != 'jpg' 
                & fileExtension != 'png'
                & fileExtension != 'jpeg'
                & fileExtension != 'pdf') {
                    error = 'El tipo de archivo seleccionado no es válido. Sólo se permiten imágenes de tipo jpg, png o jpeg.';
            }

            if (fileSize >= 3 ) {
                error = 'El archivo seleccionado tiene un peso mayor al permitido. Sólo se permiten imágenes de hasta 3MB.';
            }

            let formData = new FormData();
            formData.append('file', params.file);
            
            let options = {
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            }
            apiAxios.post(`solicitudes/${params.solicitudId}/campo/${params.formularioCampoId}/files/replace`, formData, options)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}